<template>
    <div class='card'>
        <div class='performance-title' v-if="$slots.header">
            <slot name='header'></slot>
        </div>
        <div class='type-card' v-for='(quote, i) of quotes' :key='i' @click="onTabClicked(quote.qmSymbol)">
            <MiniQuotesSimple class='performance-type' :name="quote.name" :change="quote.priceData.change" :changepercent="quote.priceData.changepercent" :icon="quote.icon" @quote-touched="onTabClicked(quote.qmSymbol)"/>
        </div>
    </div>
</template>

<script>
import MiniQuotesSimple from './MiniQuotesSimple';

import DataServices from '@/service/DataService';
import ResearchService from '../../service/ResearchService';

export default {
    name: 'MarketPerformance',

    components: {
        MiniQuotesSimple
    },

    props: {
        performanceQuotes: {
            type: Array,
            required: true
        },
        quoteParams: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            quotes: this.performanceQuotes,
            params: this.quoteParams,
            refetcher: null,
            justMounted: false,
        }
    },
	
    mounted() {
        this.justMounted = true;
        this.fetchData();
        this.startPollingEtfs();
       
    },

    activated() {
        if( !this.justMounted ) {
            this.startPollingEtfs();
        }
        else {
            this.justMounted = false;
        }
    },

    deactivated() {
        
        this.stopPollingEtfs();
    },

    unmounted() {
        
        this.stopPollingEtfs();
    },

    methods: {
        startPollingEtfs() {
			if( this.refetcher ){
				clearInterval(this.refetcher);
			}
			this.refetcher = setInterval(()=> {
				this.fetchData();
			}, 5000);
		},

		stopPollingEtfs() {
			if( this.refetcher ){
				clearInterval(this.refetcher);
			}
		},

		async fetchData() {
            if( this.$store.state.registered) {
                await DataServices.getSnapQuotes(this.params).then(resp => {
                        
                    let quoteData = resp.data.results.quote;
                    if( quoteData) {
                        this.quotes.forEach( quote => {
                            quoteData.forEach( q => {
                                if( q.key.symbol == quote.qmSymbol) {
                                    if( q.pricedata ) {
                                        quote.priceData.change = q.pricedata.change;
                                        quote.priceData.changepercent = q.pricedata.changepercent;
                                    }
                                }
                            });
                        });
                        
                    }
                });
            }
		},

        onTabClicked(symbol) {
            ResearchService.setSelectedFundSymbolFromQM(symbol, () => {
                this.$router.push({ path: '/fund' });
            })
        }
    }

}
</script>

<style scoped>
*:not(.material-icons-outlined), ::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
}

.card {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 16px;
}
.card:last-child {
    margin-bottom: 0px;
}

.performance-title {
    font-size: 24px;
    font-weight: bold;
    color: #32364e;
    margin-bottom: 16px;
}

.type-card {
    margin-bottom: 16px;
    background: #F2F4FA;
    border-radius: 4px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}
.type-card:last-child {
    margin-bottom: 0px;
}

::v-deep(.type-card .min-quote .icon-container) {
    height: 40px;
    flex: 1 0 56px;

}
/* .type-card .icon-container .icon {
}
.type-card .icon-container .text {
    color: #FFFFFF;
} */
/* .type-card .icon-container.ch-up {
    background: #33CC99;
}
.type-card .icon-container.ch-down {
    background: #e63e3e;
} */

.performance-type {    
    width: 100%;
    cursor: pointer;
}

::v-deep(.type-card .min-quote .qmod-quote) {
    padding: 8px;
}
::v-deep(.type-card  .min-quote .qmod-quote .display-name) {
    font-size: 16px;
}
::v-deep(.type-card  .min-quote .qmod-quote .qmod-change-group) {
    font-size: 16px;
}

@media screen and (max-width: 768px) {
    .card {
    border-radius: 0;
}
}
</style>