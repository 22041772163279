<template>
<Carousel v-if="!isMobile()" ref="slider" :value="slides" :numVisible="1" :numScroll="1" class="custom-carousel" :circular="true"  orientation="vertical" 
    :verticalViewPortHeight="tickerHeight" :showIndicators="false" :showNavigators="false" :autoplayInterval="autoInterval" @mouseenter="pauseSlide" @mouseleave="unPauseSlide">
               
    <template #item="slide">
            <template v-for='item in slide.data.items' :key='item.name'  >
                <Button class='p-button-raised p-button-rounded ticker'  @click="onTickerClick(item)">
                    <span :class="'pill-menuitem-text ' + (item.type == 'tag' ? '' : 'sec-color' )">{{(item.type == 'tag' ? '#'+item.name : '$'+item.name )}}</span>
                </Button>
            </template>
    </template>
</Carousel>



    <div class="tab-div">
        <router-view v-slot="{ Component }">
            <keep-alive><component :is="Component" /></keep-alive>
        </router-view>
        <HomeContent v-show="$route.path == '/home' || $route.path == '/'"/>

        
    </div>
</template>

<script>

import BrowserUtils from '../utilities/BrowserUtils';
// import PillMenuBar from '../components/menu/PillMenuBar';
import EventBus from '../event-bus';
import HomeContent from '../components/home/HomeContent';
// import Economy from '../components/home/Economy';
import Carousel from 'primevue/carousel';

//import FeedService from '../service/FeedService';
import DataService from '../service/DataService';
import format from 'date-fns/format';
import add from 'date-fns/add';
import ResearchService from '../service/ResearchService';

// import Flicking from "@egjs/vue3-flicking";

// import { AutoPlay } from "@egjs/flicking-plugins";


export default {
    name: 'Home',
    data() {
        return {
            justMounted: false,
            active: false,
            // pillMenuItems: [
            //      { label: 'Home', to: '/home' },
            //      { label: 'Economy', to: '/home/economy' },
            // ],

             marqueeItems: [],
            marqueeKey: 0,
            itemIndex: 0,
           
            numLoops: 0,
            slides: [],
            autoInterval: 8000,
            //plugins: [new AutoPlay()]

        };
    },
    components: {
        // PillMenuBar,
        // Lists,
        HomeContent,
        // Economy,

        // Flicking,
        // AutoPlay
        Carousel,

    },
    computed: {

        gradientWidth() {
            if(BrowserUtils.isMobile()) {
                return "20px";
            }
            else{
                return "200px";
            }
        },

        tickerHeight() {
            if( BrowserUtils.isMobile()){
                return "3rem";
            }
            else {
                return "3.5rem";
            }
        },

         numItemsPerSlide() {
             if( BrowserUtils.isMobile()){
                return 3;
            }
            else {
                return 7;
            }
         },

        

         

    },

    mounted() {
        this.justMounted = true;
        //console.debug('mounting Home');

        this.fetchMarqueeItems();

    },
    activated() {
        this.active = true;
        //console.debug('activated Home');
        this.justMounted = false;
        if( this.sliderPaused) {
            this.unPauseSlide();
        }
    },
    deactivated() {
       // console.debug('deactivated Home');
        this.active = false;
    },
    unmounted() {
       // console.debug('unmounted Home');
    },

    methods: {

        pauseSlide() {
            //console.log("pausing slide");
            this.autoInterval = 0;
            this.sliderPaused = true;
            let theSlider = this.$refs.slider;
            if(theSlider.stopAutoplay) {
                theSlider.stopAutoplay();
            }
        },

        unPauseSlide(){
          //  console.log("unpausing slide");
            this.autoInterval = 8000;
            this.sliderPaused = false;
             let theSlider = this.$refs.slider;
            if(theSlider.startAutoplay) {
                theSlider.startAutoplay();
            }

        },
        getNextItems() {

        },

        onTickerClick(item) {
            console.log("ITem clicked i s"+ JSON.stringify(item));
            if( item.type == 'tag'){
                this.$router.push('/tag/profile/'+item.tagId)
            } else {
                ResearchService.setSelectedEquity(item.exchangeSymbol, item.symbol, () => {
      
                    this.$router.push({ path: '/equity' });
                });
            }
        },
        shuffle(array) {
            const newArray = [...array]
            const length = newArray.length

            for (let start = 0; start < length; start++) {
                const randomPosition = Math.floor((newArray.length - start) * Math.random())
                const randomItem = newArray.splice(randomPosition, 1)

                newArray.push(...randomItem)
            }

            return newArray
        },
        fetchMarqueeItems() {
            let startDate = new Date();
            startDate = add(startDate, {days: -365});
            let endDate = add(new Date(), {days: 1});
          DataService.getTopMentionedTags(50, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd')).then(response => {
               // console.log(JSON.stringify(response));
               let tags = [];
               if( response.data.status == 'success') {
                    tags = response.data.tags.map((tag)=>{
                        return {
                                name: tag.name,
                                tagId: tag.tagId,
                                type: 'tag'
                            };
                    });
               }
                
                DataService.getTopMentionedEquities(50, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd')).then( (resp) => {
                    let secs = resp.data.securities.map((security) => {
                        return {
                            name: security.symbol,
                            exchangeSymbol: security.exchangeSymbol,
                            symbol: security.symbol,
                            type: 'security'
                        };
                    });

                    let marqueeItems = this.shuffle(secs.concat(tags));
                    let numLoops = marqueeItems.length/this.numItemsPerSlide;
                    for( var i = 0; i < numLoops; ++i ) {
                        let start = this.numItemsPerSlide*i;
                        let end = start + this.numItemsPerSlide;
                        let item = { id: i, items: marqueeItems.slice(start, end)};
                       // console.log(JSON.stringify(item));
                        this.slides.push(item);
                    }
                    //console.log(JSON.stringify(this.slides));

                });
                
            });
           
        },

        

        refresh() {
            EventBus.emit('refresh-feed');
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },
    },
};
</script>


<style scoped>
 ::v-deep(.p-carousel-content) {
    text-align: center;
}

::v-deep(.p-carousel-items-content){
    white-space: nowrap;
    overflow-x: hidden;
}

::v-deep(.p-carousel-content .p-button ) {
        background: #FFFFFF;
        border: 0;
        margin-right: .8rem;
        padding: 0;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    }
    ::v-deep(.p-carousel-content .p-button:last-child ) {
        margin-right: 0px;
    }

    ::v-deep(.p-carousel-content .p-button.active) {
        background: #32364E;
        pointer-events: none;
    }

    ::v-deep(.p-carousel-content .p-button:enabled:hover) {
        background: #32364E;
        border: 0;
    }

    ::v-deep(.p-carousel-content .p-button.purple-button__secondary) {
        border: 1px solid #693BF5;
    }
   

    ::v-deep(.p-carousel-content .p-button .pill-menuitem-text) {
        color: #32364E;
        padding: 8px 16px;
    }

    ::v-deep(.p-carousel-content .p-button:enabled:hover .pill-menuitem-text),
    ::v-deep(.p-carousel-content .p-button.active .pill-menuitem-text),
    ::v-deep(.p-carousel-content .p-button .pill-menuitem-text:hover) {
        color: #FFFFFF;
    }

 .ticker {
    margin-top: 0.4rem;

   
} 
.sec-color {
    color:  #33cc99 !important;
}

.tag-button {
    font-size: 14px;
    background: #F2F4FA;
    color: #32364e;
    border: none;
    border-radius: 50px;
    justify-content: center;
    /* pointer-events: none; */

}


.refresh-btn {
    border-radius: 50px;
    color: #32364e;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2) !important;
}
.refresh-btn:hover {
    color: white;
}
::v-deep(.feed-type-dropdown .p-inputtext) {
    font-size: 14px !important;
    font-weight: 400 !important;
    display: flex !important;
    color: #32364e;
}
::v-deep(.feed-type-dropdown .pi-chevron-down) {
    color: #32364e;
}
.feed-type-dropdown {
    padding: 0px 8px;
    border-radius: 50px;
    width: 138px;
    border-color: #33cc99;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2) !important;
}

 @media (max-width: 760px) {
  
    ::v-deep(.vue3-marquee) {
        --gradient-width: 50px;
    }   

    ::v-deep(.p-carousel-content .p-button .pill-menuitem-text) {
        font-size: 0.8rem;
        padding: 12px 16px;
    }

    .ticker {
        margin-top: 0.4rem;

    
    } 
 
} 


</style>