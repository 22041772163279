<template>
    <div class="home-portfolio-container">
        <p class="placeholder-text" v-if="!portfolioLoaded">Loading portfolio data performance data...</p>
        <div v-if="portfolioLoaded && noLinkedAccount && !homePortfolioError">
            <h2 class="portfolio-header">Connect Your Account</h2>
            <Button label="Go to Portfolio" @click="goToPortfolio" icon="pi pi-chart-line" iconPos="left" class="portfolio-button" />
        </div>
        <div class="portfolio-header-container" v-if="portfolioLoaded && !noLinkedAccount && !homePortfolioError">
            
            <div class="title-legend-container">
                <div class="chart-header-container">
                    <h2 class="chart-header">Performance</h2>
                    <Dropdown v-if="multipleAccounts" class="account-selector" v-model="selectedAccountName" @change="()=>changeAccount(selectedAccountName)" :options="visibleAccountsList" />
                </div>
                <div class="legend" v-if="multipleReturnDataPoints">
                    <div class="portfolio-legend">
                        <div></div>
                        <p class="legend-text">Portfolio</p>
                    </div>
                    <div class="comparison-legend">
                        <div></div>
                        <p class="legend-text">{{comparisonIndex}}</p>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="chart-container" v-if="portfolioLoaded && !noLinkedAccount && !homePortfolioError">

            <PerformanceChart  :key="performanceChartKey"  :portfolioReturns="percentReturnSeries" :comparisonReturns="comparisonReturnSeries" :returnDates="returnDateSeries" :selectedAccount="selectedAccountData"
                v-if="multipleReturnDataPoints && selectedAccountData" :comparisonIndex="comparisonIndex"/>

            <p v-else class="insufficient-return-data">We were unable to load any performance data for this account.</p>
        </div>
        <div class="chart-container" v-if="homePortfolioError">
            <p class="insufficient-return-data">There was an error loading your portfolio data. Visit the portfolio feature and if the problem persists, contact support.</p>
        </div>
    </div>
</template>

<script>
import PerformanceChart from './home-portfolio/PerformanceChart.vue';

import Dropdown from 'primevue/dropdown';

import PortfolioService from '../../service/PortfolioService';
import DataService from '../../service/DataService';

import format from 'date-fns/format';
import EventBus from '../../event-bus';
import moment from 'moment';
import {mapState} from 'vuex';

const COMP_SYMBOLS = ['SPY', 'XIC:CA'];

export default {
    components: {
        Dropdown,
        PerformanceChart,
    },
    props: {
        showGroupPortfolios: {
            type: Boolean,
            required: false
        },
    }, 
    data() {
        return {
            accountsLoaded: false,
            perfLoaded: false,
            noLinkedAccount: false,
            accountNamesList: [],
            accountDataList: [],
            hiddenAccountsList: [],
            visibleAccountsList: [],
            selectedAccountName: null,
            selectedAccountData: null,
            returnDateSeries: [],
            percentReturnSeries: [],
            comparisonReturnSeries: [],
            comparisonSymbol: 'SPY',
            returnStartDate: null,
            returnEndDate: null,
            homePortfolioError: false,
            performanceChartKey: 0,
            chartData: {
                labels: this.returnDateSeries,
                datasets: [
                    {
                        label: 'Portfolio',
                        data: this.percentReturnSeries,
                        borderColor: '#3C9',
                        tension: 0.2,
                        pointBackgroundColor: '#3C9',
                        pointBorderColor: '#3C9',
                        pointHoverRadius: 5,
                    },
                    {
                        label: this.comparisonIndex,
                        data: this.comparisonReturnSeries,
                        borderDash: [2, 5],
                        borderJoinStyle: 'round',
                        borderColor: '#bfbfbf',
                        tension: 0.2,
                        pointBackgroundColor: '#bfbfbf',
                        pointBorderColor: '#bfbfbf',
                        pointHoverRadius: 5,
                    },
                ],
            },

            chartOptions: {
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        font: {
                            family: 'Trebuchet MS',
                        },
                    },
                },
                scales: {
                    xAxis: {
                        grid: {
                            display: false,
                            drawBorder: false,
                        },
                        ticks: {
                            font: {
                                family: 'Trebuchet MS',
                            },
                            color: '#999',
                        },
                    },
                    yAxis: {
                        grid: {
                            borderDash: [1, 5],
                            drawBorder: false,
                            tickColor: '#fff',
                            color: '#BFBFBF',
                            font: {
                                family: 'Trebuchet MS',
                                color: '#999',
                            },
                        },
                        ticks: {
                            padding: 8,
                            font: {
                                family: 'Trebuchet MS',
                            },
                            color: '#999',
                        },
                    },
                },
            },
        };
    },

    computed: {
        ...mapState(['selectedGroup', 'currentGroupPortfolios']),

        comparisonIndex() {
            if( this.selectedAccountData?.currencyId == 1) {
               
                return "TSX";
            }
            else {
                
                return 'S&P 500';
            }
        },

        multipleReturnDataPoints() {
            return this.percentReturnSeries.length > 1;
        },

        multipleAccounts() {
            return this.visibleAccountsList.length > 0;
        },

        portfolioPerformanceData() {
            return this.$store.getters['portfolioPerformanceData'];
        },

        comparisonPerformanceData() {
            return this.$store.getters['comparisonPerformanceData'];
        },

        accountList() {
            return this.$store.getters['accountList'];
        },

        recentlyViewedPortfolio() {
            return this.$store.getters['recentPortfolio'];
        },

        hasPortfolioData() {
            return this.accountList && this.recentlyViewedPortfolio && this.comparisonPerformanceData && this.portfolioPerformanceData && this.recentlyViewedPortfolio.portfolioName;
        },

        visibleAccounts() {
            return this.visibleAccountsList.length > 0;
        },

        portfolioLoaded() {
            // console.log("portfolioLoaded", this.accountsLoaded, this.perfLoaded);
            return this.accountsLoaded && this.perfLoaded;
        }
    },

    watch: {
        currentGroupPortfolios(newVal, oldVal) {
            // console.log("currentGroupPortfolios watch", newVal, oldVal);
            if(this.showGroupPortfolios){
                if(!newVal[1] || !oldVal ||(newVal[1]?.groupId != oldVal[1]?.groupId && this.selectedGroup?.groupId === newVal[1]?.groupId)){
                    this.setListOfAccounts(newVal, "currentGroupPortfolios()");
                }
            }
        },

        // selectedGroup() {
        //     this.refreshDefaultPortfolio();
        // },
    },
     created() {
        EventBus.off('registration-completed');
        EventBus.on('registration-completed', this.initializePortfolio);
    },

    mounted(){
        // console.log("HomePortfolio --- 1 step --- mounted()", this.showGroupPortfolios, this.currentGroupPortfolios);
        if(this.showGroupPortfolios && this.currentGroupPortfolios){
            this.setListOfAccounts(this.currentGroupPortfolios, "mounted()");
        } else {
            this.initializePortfolio();
        }
    },

    activated() {
        this.initializePortfolio();
    },

    deactivated() {
        this.accountsLoaded = false;
        this.perfLoaded = false;
        this.noLinkedAccount = false;
        this.accountNamesList = [];
        this.accountDataList = [];
        this.hiddenAccountsList = [];
        this.visibleAccountsList = [];
        this.selectedAccountName = null;
        this.selectedAccountData = null;
        this.returnDateSeries = [];
        this.percentReturnSeries = [];
        this.comparisonReturnSeries = [];
        this.returnStartDate = null;
        this.returnEndDate = null;
        this.homePortfolioError = false;
    },

    methods: {
        resetLoadedState(val){
            this.accountsLoaded = val;
            this.perfLoaded = val;

        },


        initializePortfolio() {
            // console.log("HomePortfolio - 2(2) step - initializePortfolio()", !this.showGroupPortfolios, this.$store.state.registered, this.hasPortfolioData);
            if (!this.showGroupPortfolios && this.$store.state.registered) {
                if (this.hasPortfolioData) {
                    this.getPortfolioDataFromStore();
                } else {
                    this.resetLoadedState(false);
                    this.getListOfAccounts();
                }
            }
            // else {
            //     console.log("not initializing portfolios yet as user is not registered");
            // }
        },

        getPortfolioDataFromStore() {
            this.resetLoadedState(false);

            const { accountNames, accountData, visibleAccounts, hiddenAccounts } = this.accountList;
            this.accountNamesList = accountNames;
            this.accountDataList = accountData;
            this.visibleAccountsList = visibleAccounts;
            this.hiddenAccountsList = hiddenAccounts;

            const { portfolioName } = this.recentlyViewedPortfolio;
            this.changeAccount(portfolioName);

            const { percentReturns, returnDates } = this.portfolioPerformanceData;
            this.percentReturnSeries = percentReturns;
            this.returnDateSeries = returnDates;

            this.comparisonReturnSeries = this.comparisonPerformanceData;

            this.resetLoadedState(true);
            // console.log("HomePortfolio - 3(1) step - getPortfolioDataFromStore()");
        },

        // eslint-disable-next-line no-unused-vars
        setListOfAccounts(accountsList, context){
            // console.log("HomePortfolio - 2(1) || 4(1) step - setListOfAccounts()", accountsList, context);
            this.accountsLoaded = true;

            if (accountsList.length == 0) {
                this.noLinkedAccount = true;
                return;
            }

            this.getDataFromListOfAccounts(accountsList);
        },

        getListOfAccounts() {
            // console.log("HomePortfolio - 3(2) step - getListOfAccounts()");
            PortfolioService.getListOfAccounts().then(accountsList => {
                if (accountsList.status === 200) {
                    this.setListOfAccounts(accountsList.data.accounts, "getListOfAccounts()");
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error getting accounts.', life: 1500, group: 'center' });
                }
                this.accountsLoaded = true;
            })
        },

        getDataFromListOfAccounts(accountsList) {
            this.accountNamesList = [];
            this.accountDataList = [];
            this.hiddenAccountsList = [];
            this.visibleAccountsList = [];

            accountsList.forEach((acct) => {
                acct['fullName'] = this.buildAcctName(acct);
                const accountName = acct.fullName;//name;
                this.accountNamesList.push(accountName);
                this.accountDataList.push(acct);

                if (acct.hidden === true) {
                    this.hiddenAccountsList.push(accountName);
                } else {
                    this.visibleAccountsList.push(accountName);
                }
            });
            this.refreshDefaultPortfolio();
        },
        
        refreshDefaultPortfolio() {
            if(this.selectedGroup && this.selectedGroup.defaultGroupPortfolioId){
                this.changeAccount(this.accountDataList.find(portfolio => portfolio.groupPortfolioId == this.selectedGroup.defaultGroupPortfolioId)?.name);
            }
            if(!this.selectedGroup || !this.selectedGroup.defaultGroupPortfolioId || !this.selectedAccountName){
                this.changeAccount(this.visibleAccountsList[0]);
            }
            // console.log("refreshDefaultPortfolio", this.selectedAccountName);
        },

        buildAcctName(acct){
            if( !acct.partialAccountNum || acct.partialAccountNum == '' && !acct.extAccountId){
                return acct.name;
            }
            else if( !acct.partialAccountNum || acct.partialAccountNum == ''){
                return acct.name+" ("+acct.extAccountId.substring(0,8)+")";
            }
            else {
               try {
                    if( acct.partialAccountNum.length > 9) {
                        let lastDash = acct.partialAccountNum.lastIndexOf("-");
                        if( lastDash >= 0 ) {
                            return acct.name+" ("+acct.partialAccountNum.substring(lastDash+1)+")";
                        }
                        else {
                            return acct.name+" ("+acct.partialAccountNum.substring(acct.partialAccountNum.length-10)+")";
                        }
                    }
                    else {
                        return acct.name+" ("+acct.partialAccountNum+")";
                    }
                }catch(error) {
                    console.error("error building name returning default:"+error);
                    return acct.name+" ("+acct.partialAccountNum+")"; 
                }
            }
        },

        performanceFunc(portfolioPerformanceSeries) {
            if (portfolioPerformanceSeries.status === 200) {
                this.getDataFromPortfolioPerformance(portfolioPerformanceSeries.data);
                if (this.returnStartDate && this.returnEndDate) {
                // interval = 0 as its not used, intervalType = 'Y' so we get eod data instead of interday values
                    this.getComparisonPerformance(this.comparisonSymbol, 0, 'Y', format(new Date(this.returnStartDate), 'yyyy-MM-dd'), format(new Date(this.returnEndDate), 'yyyy-MM-dd'));
                }
            }
            this.perfLoaded = true;
        },

        getGroupPortfolioReturns(portfolioId) {
            PortfolioService.getGroupPortfolioReturns(portfolioId).then(this.performanceFunc);
        },

        getPortfolioPerformance(accountId) {
            PortfolioService.getPortfolioPerformance(accountId).then(this.performanceFunc);
        },

        getDataFromPortfolioPerformance(portfolioReturnData) {
            this.percentReturnSeries = [];
            this.returnDateSeries = [];

            if (portfolioReturnData.status === 'error') {
                //this.$toast.add({ severity: 'error', summary: 'Error getting portfolio performance data.', detail: 'Contact INVRS support for help by clicking your profile picture.', life: 4000, group: 'center' });
                return;
            }

            const portfolioPerformanceSeries = portfolioReturnData.dataPoints;

            if (portfolioPerformanceSeries.length > 0) {
                const mostRecentPortfolioData = portfolioPerformanceSeries[portfolioPerformanceSeries.length - 1];
                const startingPortfolioData = portfolioPerformanceSeries[0];

                this.returnStartDate = startingPortfolioData.date;
                this.returnEndDate = mostRecentPortfolioData.date;

                portfolioPerformanceSeries.forEach((data) => {
                    this.percentReturnSeries.push(data.value * 100);

                    const date = moment(data.date);
                   
                    const formattedDate = date.format("MM/DD");
                    this.returnDateSeries.push(formattedDate);
                });
            }
        },

        getComparisonPerformance(symbol, interval, intervalType, startDate, endDate) {
           this.retryingGetComparisonPerformance(symbol, interval, intervalType, startDate, endDate, 0, 2);
            
        },

        async retryingGetComparisonPerformance(symbol, interval, intervalType, startDate, endDate, numTimes, maxTries) {
            
                 
                await DataService.getPriceHistory(symbol, interval, intervalType, startDate, endDate).then(comparisonPerformance=>{
                    if (comparisonPerformance.status === 200) {
                    this.getDataFromComparisonPerformance(comparisonPerformance.data.results);
                    
                    }
                    //this.portfolioLoaded = true;
                    this.perfLoaded = true;
                    this.performanceChartKey++;
                })
                .catch(error => {
                    console.log("Error getting comparisong perf."+error);
                    if( numTimes < maxTries ) {
                        console.log("Might be sid delay issue trying again in 2 seconds");
                        setTimeout(() => {
                            //console.log("recursion")
                            this.retryingGetComparisonPerformance(symbol, interval, intervalType, startDate, endDate, numTimes+1, maxTries);
                        }, 5000);
                        
                    }
                    else {
                        console.log("tried "+ numTimes + " times giving up with error "+ error);
                    }
                

                });

            
        },

        dateExistsInComparison(aDate, comparisonArray) {
            //"date":"2022-11-18",
           for( var i = 0; i < comparisonArray.length; ++i ) {
                let data = comparisonArray[i];
                const date = moment(data.date);
                   
                const formattedDate = date.format("MM/DD");
                if( aDate === formattedDate ){
                    return true;
                }
            }
            //console.log(aDate +" doesn't exist in comparison data...removing");
            return false;
        },

        getDataFromComparisonPerformance(comparisonPerformance) {
            this.comparisonReturnSeries = [];

            const comparisonArray = comparisonPerformance.history[0].eoddata;

            // The returns may have data points that don't exist in the comparison data series.
            // we let the comparison data series dictate if the datapoint should exist on the chart by removing
            // any data points that don't have a corresponding data point in the comparison data series.
           
            //console.log("adjusting comparison data points")
            let toRemove = [];
            for( var i = 0; i < this.returnDateSeries.length; ++i ) {
                if( !this.dateExistsInComparison(this.returnDateSeries[i], comparisonArray) ) {
                    toRemove.push(i);
                }
            }
            let localReturnDateSeries =  [...this.returnDateSeries]; // clone to avoid triggering reactivity until we're done
            let localPercentReturnSeries = [...this.percentReturnSeries];
            //console.log("toRemove length is "+ toRemove.length);
            try {
                for( var j = 0; j < toRemove.length; ++j ) {
                    //console.log("adjusting return date series " +j)
                    localReturnDateSeries.splice(toRemove[j]-j, 1);
                    //console.log("adjusting percent Return Series "+ j)
                    localPercentReturnSeries.splice(toRemove[j]-j, 1);
                    //console.log("done adjusting " +j);
                }
                
            }catch( error) {
                console.error("error adjusting returns ", error);
            }
           

            // console.log("return Date series length after adj: "+ localReturnDateSeries.length);
            // console.log("precent return series length after adj: "+ localPercentReturnSeries.length);
           
                
            const comparisonStartingValue = comparisonArray[comparisonArray.length - 1].close;
            //console.log("about to iterate through comparison array")
            let localComparisonReturnSeries = [...this.comparisonReturnSeries];// clone
            comparisonArray.forEach((data) => {
                //console.log("data.close = "+ data.close);
                //console.log("comparisonStartingValue = "+ comparisonStartingValue)
                //console.log("(data.close / comparisonStartingValue - 1) = "+ (data.close / comparisonStartingValue - 1));
                const comparisonReturn = (data.close / comparisonStartingValue - 1) * 100;
                localComparisonReturnSeries.unshift(comparisonReturn);
            });

            this.returnDateSeries = localReturnDateSeries;
            this.percentReturnSeries = localPercentReturnSeries;
            this.comparisonReturnSeries = localComparisonReturnSeries;
                
           
        },

        changeAccount(accountName) {
            // console.log("changeAccount", accountName, this.accountDataList);
            if(!accountName){
                this.selectedAccountName = null;
                this.selectedAccountData = null;
                this.accountsLoaded = false;
                return;
            }

            this.selectedAccountName = accountName;
            this.selectedAccountData = this.accountDataList.find(account => account.fullName === this.selectedAccountName);
            if( this.selectedAccountData.currencyId == 1) {
                this.comparisonSymbol = COMP_SYMBOLS[1];
            }
            else {
                this.comparisonSymbol = COMP_SYMBOLS[0];
            }
            this.accountsLoaded = true;

            if(this.selectedAccountData.accountTypeId == -1){
                this.getGroupPortfolioReturns(this.selectedAccountData.groupPortfolioId);
            } else {
                this.getPortfolioPerformance(this.selectedAccountData.investmentAccountId);
            }
        },

        goToPortfolio() {
            this.$router.push('/portfolios');
        },
    },
};
</script>

<style scoped>

.portfolio-header,
.chart-header {
    font-size: 24px;
    color: #32364e;
}

.portfolio-button {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
}

::v-deep(.portfolio-button > .pi) {
    font-size: 18px;
}

.chart-header {
    margin-bottom: 4px;
}

.portfolio-header-container {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.insufficient-return-data,
.placeholder-text {
    font-family: 'Trebuchet MS';
    font-size: 16px;
    color: #999;
    margin-top: 12px;
}

.placeholder-text {
    text-align: center;
    margin-bottom: 8px;
}

::v-deep(.p-dropdown) {
    border: 2px solid #32364E;
    border-radius: 50px;
    padding: 2px;
    margin-left: 8px;
    max-width: calc(100% - 145px);
}

::v-deep(.p-dropdown:hover, .p-dropdown:active, .p-dropdown:focus, .p-dropdown.p-focus, .p-inputwrapper-focus) {
    border-color: #32364e;
}

::v-deep(.p-dropdown-label) {
    color: #32364e;
    font-size: 1rem;
    padding: 2px 0px 2px 4px;
}

::v-deep(.p-dropdown-trigger) {
    width: 1.5rem;
}

::v-deep(.p-dropdown-trigger-icon) {
    color: #32364e;
    font-size: 0.9rem;
}

.p-dropdown:not(.p-disabled).p-focus,
::v-deep(.p-dropdown:not(.p-disabled):focus) {
    outline: none !important;
    box-shadow: none !important;
    border-color: unset;
}

.chart-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.legend {
    display: flex;
    margin-top: 4px;
}

.legend-text {
    font-family: 'Trebuchet MS';
    color: #999;
    line-height: 1;
}

.portfolio-legend,
.comparison-legend {
    margin-right: 8px;
    display: flex;
    align-items: center;
}

.portfolio-legend div {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    background-color: #3c9;
    margin-right: 4px;
}

.comparison-legend div {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    background-color: #999;
    margin-right: 4px;
}

</style>
