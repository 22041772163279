<template>
    <Chart :data="chartData" :options="chartOptions" type="line" />
</template>

<script>
import Chart from 'primevue/chart';

export default {
  components: {
    Chart,
  },

  props: {
    portfolioReturns: {
      required: true,
    },

    comparisonReturns: {
      required: true,
    },
    returnDates: {
      required: true,
    },
    comparisonIndex: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      chartData: {
          labels: this.returnDates,
          datasets: [
              {
                  label: 'Portfolio',
                  data: this.portfolioReturns,
                  borderColor: '#3C9',
                  tension: 0.2,
                  pointBackgroundColor: '#3C9',
                  pointBorderColor: '#3C9',
                  pointHoverRadius: 5,
                  borderWidth: 2,
              },
              {
                  label: this.comparisonIndex,
                  data: this.comparisonReturns,
                  borderWidth: 2,
                  borderColor: '#bfbfbf',
                  tension: 0.2,
                  pointBackgroundColor: '#bfbfbf',
                  pointBorderColor: '#bfbfbf',
                  pointHoverRadius: 2,
              },
          ],
      },

      chartOptions: {
          elements: {
            point: {
              radius: 1,
            }
          },
          plugins: {
              legend: {
                  display: false,
              },
              datalabels: {
                  font: {
                      family: 'Trebuchet MS',
                  },
              },
          },
          scales: {
              xAxis: {
                  grid: {
                      display: false,
                      drawBorder: false,
                  },
                  ticks: {
                      font: {
                          family: 'Trebuchet MS',
                      },
                      color: '#999',
                  },
              },
              yAxis: {
                  grid: {
                      borderDash: [1, 5],
                      drawBorder: false,
                      tickColor: '#fff',
                      color: '#BFBFBF',
                      font: {
                          family: 'Trebuchet MS',
                          color: '#999',
                      },
                  },
                  ticks: {
                      padding: 0,
                      font: {
                          family: 'Trebuchet MS',
                      },
                      color: '#999',
                  },
              },
          },
      },
    }
  },

 
}
</script>