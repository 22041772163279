<template>
    <div class="tab-content2">
       
        <div class="col-container p-grid">
            <div class="left-col p-col-12 p-md-7 p-d-md-block p-d-none" v-if="!isMobile()">
                 
                <FeedActivityList ref="feedList" templateForComponent="Home" :notesOnly="showNotesOnly">
                    <div class="card status-editor-card" style="padding-top:12px;">
                        <StatusEditor @filter-followed-user-posts="filterFollowedUserPosts"/>
                    </div>
                </FeedActivityList>
            </div>
            <div class="right-col p-col-12 p-md-5 p-d-md-block p-d-none" v-if="!isMobile()">
                <ScrollPanel style="width: 100%; height: 100vh">

                    <div class="card contest-and-performance-container">
                        <TabMenu :model="contestAndPerformanceItems" :activeIndex="contestAndPerformanceActiveIndex" style="position:unset;z-index:auto;">
                            <template #item="{item}">
                                <button @click="viewContestAndPerformance(item.label)">
                                    {{ item.label }}
                                </button>
                            </template>
                        </TabMenu>
                        <div v-if="contestAndPerformanceActiveIndex == 0">
                            <ContestDetails homePage />
                        </div>
                        <HomePortfolio v-else-if="portfoliosFeatureEnabled() && portfoliosSettingsEnabled()" />
                    </div>


                    <PillMenuBar :navigationItems="marketsDiscoverMenuItems" headerType="sub" class="markets-discover-header-menu p-d-md-flex p-d-none" />
                    <div class='discover-container' v-if="selectedFeed === 'Discover'">
                        
                        <div class="card" style="padding: 0px">
                            <PopularNotes />
                        </div>
                        <div class="card" style="padding: 16px">
                            <PopularAssets />
                        </div>
                        <WhoToFollowList :type="WHO_TO_FOLLOW_TYPES['HOME_DISCOVER']" />
                        <div class="margin-bottom-container p-d-md-none"></div>
                    </div>
                    <div class='markets-container' v-else-if="selectedFeed === 'Markets'">
                        <MarketPerformance class="market-performance" :performanceQuotes="sectorIndicesQuotes" :quoteParams="indicesParam">
                            <template #header> Market Performance </template>
                        </MarketPerformance>
                        <MarketPerformance class="sector-performance" :performanceQuotes="sectorPerformancesQuotes" :quoteParams="sectorPerformancesParam">
                            <template #header> Sector Performance </template>
                        </MarketPerformance>
                        <MarketPerformance class="factor-performance" :performanceQuotes="factorPerformancesQuotes" :quoteParams="factorPerformancesParam">
                            <template #header> Factor Performance </template>
                        </MarketPerformance>
                        <AttributionFooter authors="quoteMedia" />
                        <div class="margin-bottom-container p-d-md-none"></div>
                    </div>
                    <template v-else>
                        <div class="card news-feed-container" style="width: 100%">
                            <keep-alive>
                                <MarketNews />
                            </keep-alive>
                        </div>
                        <AttributionFooter authors="quoteMedia" />
                    </template>
                </ScrollPanel>
            </div>
            <div class="right-col p-col-12 p-md-5 p-d-md-none" v-if="isMobile()">
                <ScrollPanel style="width: 100%; height: 100vh" v-if="selectedFeedMobile === 'Discover' ||selectedFeedMobile === 'Markets' || selectedFeedMobile === 'Home'">
                    <PillMenuBar :navigationItems="marketsDiscoverMenuItems" headerType="sub" class="markets-discover-header-menu p-d-md-flex p-d-none" />
                    <template v-if="selectedFeedMobile === 'Discover'">
                       
                        <div class="card" style="padding: 0px">
                            <PopularNotes />
                        </div>
                        <div class="card" style="padding: 16px">
                            <PopularAssets />
                        </div>
                         <WhoToFollowList :type="WHO_TO_FOLLOW_TYPES['HOME_DISCOVER']" />
                        <div class="margin-bottom-container p-d-md-none"></div>
                    </template>
                    <template v-else-if="selectedFeedMobile === 'Markets'">
                        <MarketPerformance class="market-performance" :performanceQuotes="sectorIndicesQuotes" :quoteParams="indicesParam">
                            <template #header> Market Performance </template>
                        </MarketPerformance>
                        <MarketPerformance class="sector-performance" :performanceQuotes="sectorPerformancesQuotes" :quoteParams="sectorPerformancesParam">
                            <template #header> Sector Performance </template>
                        </MarketPerformance>
                        <MarketPerformance class="factor-performance" :performanceQuotes="factorPerformancesQuotes" :quoteParams="factorPerformancesParam">
                            <template #header> Factor Performance </template>
                        </MarketPerformance>
                        <AttributionFooter authors="quoteMedia" />
                        <div class="margin-bottom-container p-d-md-none"></div>
                    </template>
                    <template v-else-if="selectedFeedMobile === 'Home'">
                        <a class="post-floating-button p-d-md-none"  v-touch:tap="openStatusEditorDialog">
                            
                            <img src="/assets/images/post.png" />
                        </a>
                        <a class="question-floating-button p-d-md-none"  v-touch:tap="openQuestionEditorDialog">
                           
                            <img src="/assets/images/ask.png" />
                        </a>
                        <a class="motion-floating-button p-d-md-none"  v-touch:tap="openCreateMotionModal">
                           
                            <img src="/assets/images/motion_icon2.png" />
                        </a>
                        <FeedActivityList ref="feedList" :notesOnly="showNotesOnly"> </FeedActivityList>
                        <StatusEditorDialog ref="statusEditorModal" />
                        <div class="margin-bottom-container p-d-md-none"></div>
                    </template>
                    
                </ScrollPanel>
                <div class="card news-feed-container" v-else-if="selectedFeedMobile === 'News'">
                    <keep-alive>
                        <MarketNews />
                    </keep-alive>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import BrowserUtils from '../../utilities/BrowserUtils';
import FeedActivityList from '../feed/FeedActivityList.vue';
//import WhoToFollowList from '../feed/WhoToFollow/WhoToFollowList.vue';
//import PopularNotes from '../feed/PopularNotes.vue';
//import PopularAssets from '../feed/PopularAssets.vue';
import StatusEditor from '../feed/StatusEditor.vue';
import MarketPerformance from '../QuoteMedia/MarketPerformance.vue';
import PillMenuBar from '../menu/PillMenuBar.vue';
//import StatusEditorDialog from '../feed/StatusEditorDialog.vue';
import AttributionFooter from '../footer/AttributionFooter.vue';
import HomePortfolio from '../portfolio/HomePortfolio.vue';
//import MarketNews from '../QuoteMedia/MarketNews.vue';
import ContestDetails from '../contests/components/ContestDetails.vue';

import GroupService from '../../service/GroupService';
import DataServices from '@/service/DataService';
import PortfolioService from '../../service/PortfolioService';
import ResearchService from '../../service/ResearchService';

import ScrollPanel from 'primevue/scrollpanel';
import TabMenu from 'primevue/tabmenu';

import EventBus from '../../event-bus';
import {mapState} from 'vuex';

import { WHO_TO_FOLLOW_TYPES } from '../../common/constants';

const contestAndPerformanceLabels={
    contests: 'Contests',
    performance: 'Performance'
}

export default {
    name: 'HomeContent',
    components: {
        PillMenuBar,
        StatusEditorDialog: defineAsyncComponent(() =>
            import('../feed/StatusEditorDialog.vue')
        ),
        ScrollPanel,
        MarketPerformance,
        FeedActivityList,
        WhoToFollowList: defineAsyncComponent(() =>
            import('../feed/WhoToFollow/WhoToFollowList.vue')
        ),
        StatusEditor,
        PopularNotes: defineAsyncComponent(() =>
            import('../feed/PopularNotes.vue')
        ),
        PopularAssets: defineAsyncComponent(() =>
            import('../feed/PopularAssets.vue')
        ),
        AttributionFooter,
        HomePortfolio,
        MarketNews: defineAsyncComponent(() =>
            import('../QuoteMedia/MarketNews.vue')
        ),
        ContestDetails,
        TabMenu
    },
    data() {
        return {
            //CONSTANTS
            WHO_TO_FOLLOW_TYPES,
            contestAndPerformanceLabels,

            justMounted: false,
            active: false,

            followedUsersOnly: false,

            menu: [
                {
                    label: 'Home',
                    icon: 'pi pi-fw pi-home',
                    action: this.viewHomeFeedMobile,
                },
                {
                    label: 'Markets',
                    icon: 'pi pi-fw pi-dollar',
                    action: this.viewMarketsFeedMobile,
                },
                {
                    label: 'Discover',
                    icon: 'pi pi-fw pi-search',
                    action: this.viewDiscoverFeedMobile,
                },
                {
                    label: 'News',
                    icon: 'pi pi-fw pi-book',
                    action: this.viewNewsFeedMobile,
                },
                {
                    label: 'Groups',
                    icon: 'pi pi-fw pi-users',
                    action: () => {
                        this.activetab = 4;
                        this.$router.push({ path: '/groups' });
                    },
                    disabled: !GroupService.groupsEnabled(),
                },
            ],

            activetab: 0,
            selectedFeedMobile: 'Home',

            sectorIndicesQuotes: [
                { symbol: 'SPY', qmSymbol: 'SPY', name: 'S&P 500' },
                { symbol: 'QQQ', qmSymbol: 'QQQ', name: 'Nasdaq 100' },
                { symbol: 'DIA', qmSymbol: 'DIA', name: 'Dow Jones' },
                { symbol: 'XIC', qmSymbol: 'XIC:CA', name: 'TSX Composite' },
            ],
            sectorPerformancesQuotes: [
                { symbol: 'IYW', qmSymbol: 'IYW', name: 'Technology' },
                { symbol: 'IYH', qmSymbol: 'IYH', name: 'Healthcare' },
                { symbol: 'KXI', qmSymbol: 'KXI', name: 'Consumer Staples' },
                { symbol: 'RXI', qmSymbol: 'RXI', name: 'Consumer Discretionary' },
                { symbol: 'IYG', qmSymbol: 'IYG', name: 'Financial Services' },
                { symbol: 'XLI', qmSymbol: 'XLI', name: 'Industrials' },
                { symbol: 'IDU', qmSymbol: 'IDU', name: 'Utilities' },
                { symbol: 'VNQ', qmSymbol: 'VNQ', name: 'Real Estate' },
                { symbol: 'VOX', qmSymbol: 'VOX', name: 'Communications' },
                { symbol: 'IYE', qmSymbol: 'IYE', name: 'Energy' },
                { symbol: 'IYM', qmSymbol: 'IYM', name: 'Materials' },
            ],
            factorPerformancesQuotes: [
                { symbol: 'IWF', qmSymbol: 'IWF', name: 'Growth' },
                { symbol: 'IWD', qmSymbol: 'IWD', name: 'Value' },
                { symbol: 'PDP', qmSymbol: 'PDP', name: 'Momentum' },
                { symbol: 'SPLV', qmSymbol: 'SPLV', name: 'Low Volatility' },
                { symbol: 'VYM', qmSymbol: 'VYM', name: 'High Dividend' },
                { symbol: 'IJR', qmSymbol: 'IJR', name: 'Small Cap' },
                { symbol: 'IJH', qmSymbol: 'IJH', name: 'Mid Cap' },
                { symbol: 'IVV', qmSymbol: 'IVV', name: 'Large Cap' },
            ],

            indicesParam: 'SPY,QQQ,DIA,XIC:CA',
            sectorPerformancesParam: 'IYW, IYH, KXI, RXI, IYG, XLI, IDU, VNQ, VOX, IYE, IYM',
            factorPerformancesParam: 'IWF, IWD, PDP, SPLV, VYM, IJR, IJH, IVV',
            refetcher: null,

            selectedFeed: 'Markets',
            marketsDiscoverMenuItems: [
                { label: 'Markets', action: this.viewMarketsFeed, selected: true },
                { label: 'Discover', action: this.viewDiscoverFeed, selected: false },
                { label: 'News', action: this.viewNewsFeed, selected: false },
            ],
           
            contestAndPerformanceActiveIndex: 0,
            contestAndPerformanceItems: [
                { index: 0, label: contestAndPerformanceLabels.contests, selected: true },
                { index: 1, label: contestAndPerformanceLabels.performance, selected: false },
            ],

        };
    },
    
    computed: {
        ...mapState(['showNotesOnly']),
    },
    watch: {
        followedUsersOnly() {
            this.$store.commit('SET_SHOW_FOLLOWED_USER_FEEDS_ONLY', this.followedUsersOnly);
            EventBus.emit('refresh-feed');
        },

        $route() {
            this.checkFeedParam();
        },
    },

    created() {
        let iconMapForPerformanceQuotes = {
            indicesPerformance: {
                ['SPY']: 'SPY',
                ['QQQ']: 'NDQ',
                ['DIA']: 'DOW',
                ['XIC']: 'TSX',
            },
            sectorPerformance: {
                ['IYW']: 'devices',
                ['IYH']: 'health_and_safety',
                ['KXI']: 'shopping_basket',
                ['RXI']: 'shopping_cart',
                ['IYG']: 'account_balance',
                ['XLI']: 'engineering',
                ['IDU']: 'lightbulb',
                ['VNQ']: 'business',
                ['VOX']: 'phone_in_talk',
                ['IYE']: 'bolt',
                ['IYM']: 'park',
            },
            factorPerformance: {
                ['IWF']: 'GWTH',
                ['IWD']: 'VALU',
                ['PDP']: 'MTUM',
                ['SPLV']: 'LVOL',
                ['VYM']: 'HDIV',
                ['IJR']: 'SCAP',
                ['IJH']: 'MCAP',
                ['IVV']: 'LCAP',
            },
        };

        this.sectorIndicesQuotes = this.sectorIndicesQuotes.map((quote) => {
            return {
                symbol: quote.symbol,
                qmSymbol: quote.qmSymbol,
                name: quote.name,
                priceData: {
                    change: 0.0,
                    changepercent: 0.0,
                },
                icon: {
                    type: 'text',
                    value: iconMapForPerformanceQuotes['indicesPerformance'][quote.symbol],
                },
            };
        });
        this.sectorPerformancesQuotes = this.sectorPerformancesQuotes.map((quote) => {
            return {
                symbol: quote.symbol,
                qmSymbol: quote.qmSymbol,
                name: quote.name,
                priceData: {
                    change: 0.0,
                    changepercent: 0.0,
                },
                icon: {
                    type: 'symbol',
                    value: iconMapForPerformanceQuotes['sectorPerformance'][quote.symbol],
                },
            };
        });
        this.factorPerformancesQuotes = this.factorPerformancesQuotes.map((quote) => {
            return {
                symbol: quote.symbol,
                qmSymbol: quote.qmSymbol,
                name: quote.name,
                priceData: {
                    change: 0.0,
                    changepercent: 0.0,
                },
                icon: {
                    type: 'text',
                    value: iconMapForPerformanceQuotes['factorPerformance'][quote.symbol],
                },
            };
        });
      
        //console.log('created');
    },

    mounted() {
        this.justMounted = true;

        if (!this.isMobile() && this.$route.path == '/') {
            this.$router.push('/home');
        }

        this.fetchData(); // For fetching index quotes data
        EventBus.on('viewHome', this.viewHomeFeedMobile);
        EventBus.on('viewMarkets', this.viewMarketsFeedMobile);
        EventBus.on('viewDiscover', this.viewDiscoverFeedMobile);
        EventBus.on('update-comments-from-home-activity', (post) => {
            let found = this.$refs.feedList.findActivityThroughFeed(post.activity.id);

            if (found) {
                this.$refs.feedList.onCommentPosted(found);
            }
        });
        EventBus.off('view-performance');
        EventBus.on('view-performance', () => {
            this.viewContestAndPerformance(contestAndPerformanceLabels.performance);
            
            EventBus.off('view-performance');
        });

        // console.log('mounted');
    },
    activated() {
        this.active = true;

        this.checkFeedParam();

        if (!this.justMounted) {
            if (!this.isMobile()) {
                this.$router.push('/home'); // Ensures that everytime it gets brought up from the cache, that it will automatically lead you to the feed page
            }
            this.$refs.feedList?.refreshFeed();
        }
        this.justMounted = false;
        this.startPollingIndices();
       // console.log('activated home');
    },
    deactivated() {
        //console.debug('deactivated home');
        this.justMounted = false;
        this.active = false;
        this.stopPollingIndices();
    },
    unmounted() {
        this.stopPollingIndices();
    },

    methods: {

        openCreateMotionModal() {
            EventBus.emit('open-create-motion-modal', {group:null, accountId: null});
        },

        checkFeedParam() {
            const FEED_PARAM_MAP = {
                markets: 'Markets',
                discover: 'Discover',
                news: 'News',
            };
            const feedParam = this.$route.query.feed;

            if (feedParam) {
                this.selectedFeedMobile = FEED_PARAM_MAP[feedParam];
            } else {
                this.selectedFeedMobile = 'Home';
            }
        },

        portfoliosSettingsEnabled() {
            return PortfolioService.portfoliosSettingsEnabled();
        },

        portfoliosFeatureEnabled() {
            return PortfolioService.portfoliosFeatureEnabled();
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },

        initializeQuotes(quote) {
            return {
                symbol: quote.symbol,
                qmSymbol: quote.qmSymbol,
                name: quote.name,
                priceData: {
                    change: 0.0,
                    changepercent: 0.0,
                },
            };
        },
        openStatusEditorDialog() {
            this.$refs.statusEditorModal.open();
        },

         openQuestionEditorDialog() {
            this.$refs.statusEditorModal.open(null, null, true);
            
        },

        viewContestAndPerformance(name) {
            this.contestAndPerformanceActiveIndex = contestAndPerformanceLabels.contests === name ? 0 : 1;
        },

        viewMarketsFeed() {
            this.selectedFeed = 'Markets';
        },
        viewDiscoverFeed() {
            this.selectedFeed = 'Discover';
        },
        viewNewsFeed() {
            this.selectedFeed = 'News';
        },
        viewMarketsFeedMobile() {
            this.selectedFeedMobile = 'Markets';
            this.activetab = 1;
        },
        viewHomeFeedMobile() {
            this.selectedFeedMobile = 'Home';
            this.activetab = 0;
        },
        viewDiscoverFeedMobile() {
            this.selectedFeedMobile = 'Discover';
            this.activetab = 2;
        },
        viewNewsFeedMobile() {
            this.selectedFeedMobile = 'News';
            this.activetab = 3;
        },

        filterFollowedUserPosts() {
            this.followedUsersOnly = !this.followedUsersOnly;
        },

        startPollingIndices() {
            if (this.refetcher) {
                clearInterval(this.refetcher);
            }
            this.refetcher = setInterval(() => {
                this.fetchData();
            }, 5000);
        },

        stopPollingIndices() {
            if (this.refetcher) {
                clearInterval(this.refetcher);
            }
        },

        async fetchData() {
            if (this.$store.state.registered) {
                await DataServices.getSnapQuotes(this.indicesParam).then((resp) => {
                    let quoteData = resp.data.results.quote;
                    if (quoteData) {
                        this.sectorIndicesQuotes.forEach((quote) => {
                            quoteData.forEach((q) => {
                                if (q.key.symbol == quote.qmSymbol && q.pricedata) {
                                    quote.priceData.change = q.pricedata.change;
                                    quote.priceData.changepercent = q.pricedata.changepercent;
                                }
                            });
                        });
                    }
                });
            } 
            // else {
            //     console.log('not calling getSnapQuotes yet, user is not registered');
            // }
        },

        onTabClicked(symbol) {
            ResearchService.setSelectedFundSymbolFromQM(symbol, () => {
                this.$router.push({ path: '/fund' });
            });
        },
    },
};
</script>

<style>
.p-dropdown-panel {
    border-radius: 8px !important;
}
.p-dropdown-panel .p-dropdown-item.p-highlight {
    background: #32364e !important;
    color: white !important;
}
</style>

<style scoped>
.factor-performance {
    margin-bottom: 150px;
}

.contest-and-performance-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 16px !important;
}

::v-deep(.contest-and-performance-container .p-tabmenu .p-tabmenu-nav .p-tabmenuitem button) {
    background: #ffffff;
    border: solid #ffffff;
    border-width: 0 0 2px 0;
    padding: 6px;
    cursor: pointer;
}

::v-deep(.contest-and-performance-container .p-tabmenu .p-tabmenu-nav .p-tabmenuitem button:hover) {
    background: #ffffff;
    color: #32364E;
    border: solid #32364E;
    border-width: 0 0 2px 0;
    padding: 6px;
}

::v-deep(.contest-and-performance-container .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight button) {
    background: #ffffff;
    color: #33CC99;
    border: solid #33CC99;
    border-width: 0 0 2px 0;
    padding: 6px;
}

::v-deep(.contest-and-performance-container .p-tabmenu-nav) {
    text-align: center;
    border-width: 0;
    display: flex;
    justify-content:space-around;
}

::v-deep(.contest-and-performance-container .p-tabmenuitem) {
    padding-top: 0 !important;
}

::v-deep(.p-dropdown) {
    border: 2px solid #32364E;
    border-radius: 50px;
    padding: 1px 4px 1px 8px;
}

::v-deep(.p-dropdown:hover, .p-dropdown:active, .p-dropdown:focus, .p-dropdown.p-focus, .p-inputwrapper-focus) {
    border-color: #32364e;
}

::v-deep(.p-dropdown-label) {
    color: #32364e;
    font-size: 1rem;
}

::v-deep(.p-dropdown-trigger-icon) {
    color: #32364e;
    font-size: 0.9rem;
}

::v-deep(.p-dropdown-item) {
    font-family: 'Trebuchet MS';
    color: #32364e;
    border-bottom: 1px solid #333;
}

.p-dropdown:not(.p-disabled).p-focus,
::v-deep(.p-dropdown:not(.p-disabled):focus) {
    outline: none !important;
    box-shadow: none !important;
    border-color: unset;
}

::v-deep(.p-tabmenu) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
}

::v-deep(.p-tabmenu-nav) {
    height: 100%;
    display: flex;
}

::v-deep(.p-tabmenuitem) {
    width: 33.33%;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

::v-deep(.p-tabmenuitem > button) {
    display: flex;
    height: 100%;
    width: 100%;
    border: 0;
    color: #32364e;
    font-family: 'Trebuchet MS', 'Verdana';
    background: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
::v-deep(.p-grid) {
    margin: 0px;
}
::v-deep(.p-highlight > button) {
    color: #33cc99;
}


.post-floating-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 2.7rem;
    height: 2rem;
    border-radius: 20%;
    color: #ffffff;
    background: rgba(50, 54, 78, .5);/*#32364e; /*#33cc99;*/
    right: -.5rem;
    bottom: 20vh;
    cursor: pointer;
    transition: background-color 0.2s;
    box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%);
    z-index: 99;
    
    /* opacity: 0.5; */
}
.post-floating-button > img {
    width: 60%;
    padding-right: .2rem;
}

.question-floating-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 2.7rem;
    height: 2rem;
    border-radius: 20%;
    color: #ffffff;
    /* background: #32364e; #33cc99; */
    background:rgba(50, 54, 78, .5);
    right: -.5rem;
    bottom: 15vh;
    cursor: pointer;
    transition: background-color 0.2s;
    box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%);
    z-index: 99;
    /* opacity: 0.5; */
}
.question-floating-button > img {
    width: 60%;
    padding-right: .2rem;
}

.motion-floating-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 2.7rem;
    height: 2rem;
    border-radius: 20%;
    color: #ffffff;
    /* background: #32364e; #33cc99; */
    background:rgba(50, 54, 78, .5);
    right: -.5rem;
    bottom: 10vh;
    cursor: pointer;
    transition: background-color 0.2s;
    box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%);
    z-index: 99;
    /* opacity: 0.5; */
}
.motion-floating-button > img {
    width: 60%;
    padding-right: .2rem;
}


.card {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.tab-div .left-col .card {
    /* padding: 16px; */
    margin: 0 0px 16px 0;
}

.margin-bottom-container {
    margin-bottom: 10vh;
}

.col-container {
    display: flex;
}

/* .left-col {
    margin: 0 2rem;
    flex: 1;
} */
.right-col {
    /* margin: 0 2rem;
    width: 40vw;
    flex: 1; */
    padding: 0px;
}
::v-deep(.p-scrollpanel.p-component) {
    /* background: #1f2d40; */
    width: 100%;
    height: 800px;
    border: 0 none;
    /* color: rgba(255, 255, 255, 0.87); */
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

::v-deep(.p-menuitem-link .p-menuitem-icon) {
    margin-top: -3px !important;
}
::v-deep(.pi-feed-tab::before) {
    content: '\e941 ';
}
::v-deep(.pi-feed-tab::after) {
    content: '\e967';
    font-size: 0.8rem;
    margin-left: -3px;
    vertical-align: super;
}
::v-deep(.left-col .p-scrollpanel.p-component .p-scrollpanel-content) {
    padding: 0px 8px;
}
.tab-div .right-col .p-scrollpanel ::v-deep(.p-scrollpanel-content) {
    -ms-overflow-style: none;
    scrollbar-width: none;

    width: auto;
    height: 100%;
    padding: 0px 8px;
}
.tab-div .right-col .p-scrollpanel ::v-deep(.p-scrollpanel-content::-webkit-scrollbar) {
    display: none;
}

.tab-div .left-col .status-editor-card {
    padding: 16px 0px 0px;
}

::v-deep(.min-quote .qmod-quote .display-name),
::v-deep(.min-quote .qmod-quote .qmod-change-group) {
    font-size: 1.714rem;
}

.right-col .news-feed-container {
    padding: 0px;
}

::v-deep(#markNews .qmod-btn.qmod-btn-link) {
    padding: 0px;
}
/* @media (min-width: 761px) {
    .col-container {
        margin: 0 15vw;
    }
} */

@media screen and (max-width: 75em) and (min-width: 760px) {
    ::v-deep(.min-quote .qmod-quote .display-name),
    ::v-deep(.min-quote .qmod-quote .qmod-change-group) {
        font-size: 1.286rem;
    }
}

@media (max-width: 760px) {
    .tab-div {
        margin-top: 8px !important;
    }
    .col-container {
        flex-direction: column;
    }
    .right-col {
        margin: 0;
        width: 100vw;
    }
    .card {
        border-radius: 0;
        box-shadow: none;
    }
    .status-editor-card {
        margin-bottom: 16px;
    }
    .tab-div .right-col .p-scrollpanel ::v-deep(.p-scrollpanel-content) {
        padding: 0px;
    }
    ::v-deep(.left-col .p-scrollpanel.p-component .p-scrollpanel-content) {
        padding: 0px;
    }
    ::v-deep(.min-quote, .qmod-quote, .display-name),
    ::v-deep(.min-quote, .qmod-quote, .qmod-change-group) {
        font-size: 16px;
    }

    ::v-deep(#markNews .qmod-btn.qmod-btn-link) {
        right: 12px;
    }

    .right-col .news-feed-container{
        padding-right: 10px;
    }
}
</style>
