<template>
    <div class='status-form-container' id="statusFormContainer">
        <div class="status-form-top-row" stlyle="text-alight: center;" >
           
            <Button :disabled="disablePost" class='p-button p-component p-button-raised p-button-rounded green-button__secondary make-post-button' v-tooltip.left="{value: MAKE_POST_HOVER_TEXT, class: 'make-post-tooltip'}" @click='openStatusEditorDialog'>
                <img src="/assets/images/post.png"/>
                <span class="text">Post</span>
            </Button>
            
            <Button :disabled="disableAsk" class='p-button p-component p-button-raised p-button-rounded green-button__secondary ask-question-button' v-tooltip.right="{value: 'Ask a Question or create a Poll for members to answer.', class: 'create-note-tooltip'}" @click='openQuestionEditorDialog'>
                <img src="/assets/images/ask.png"/>
                <span class="text">Ask</span>
            </Button>
            <Button v-if="showPostMotionButton" :class="'p-button p-component p-button-raised p-button-rounded green-button__secondary create-motion-button '+ (userUtils.isFeatureEnabled('collaborativePortfoliosEnabled') ? '' : 'disabled-motion-button')" 
                v-tooltip.right="{value: 'Click on this to create a Motion to Invest.  This is a suggested stock trade for one of the INVRS collaborative portfolios.  Anyone can make a Motion to Invest and anyone can vote on it.  If the motion passes then the suggested trade is executed.', class: 'create-note-tooltip'}" 
                @click="(userUtils.isFeatureEnabled('collaborativePortfoliosEnabled') ? openCreateMotionModal() : '')">
                <img src="/assets/images/motion_icon2.png"/>
                <span class="text">Motion</span>
            </Button>
           <div  style="margin-left: auto;">
                <div class="status-form-row">
                    <Button class='status-form-action-button' v-tooltip.bottom="'Search Posts - coming soon...'" style="opacity:.3;" icon='pi pi-search' />
                    <Button class='status-form-action-button' v-tooltip.bottom="'Refresh'" icon='pi pi-refresh' @click="refresh"/>
                    <Button v-if="showNotesOnlyButton" :class="{'status-form-action-button': true, 'selected': showNotesOnly}" v-tooltip.bottom="'Notes Only'" 
                        icon='pi pi-pencil' @click="toggleShowNotesOnly"/>
                </div>
        </div>
        </div>
        <TabMenu v-model:activeIndex="active" :model="menuItems" style="position:unset;" v-if="!selectedGroup">
            <template #item="slotProps" >
                <li  :class="'p-tabmenuitem ' + (slotProps.item.index == active? 'p-highlight ' : '') +(slotProps.item.feedDisabled ? 'disabled-feed' : '')" role="presentation" v-tooltip.top="slotProps.item.tooltip" @click="(slotProps.item.feedDisabled ? '' : onFeedChange(slotProps.item))">
                    <a role="menuitem" class="p-menuitem-link" :aria-label="slotProps.item.label" >
                        <span class="p-menuitem-text">{{slotProps.item.label}}</span></a>
                </li>
            </template>
        </TabMenu>
        
    </div>
    
    <StatusEditorDialog ref='statusEditorModal' :selectedContest="selectedContest" />
</template>

<script>
import StatusEditorDialog from './StatusEditorDialog';

import EventBus from '../../event-bus';
import { mapState } from 'vuex';

import TabMenu from 'primevue/tabmenu';
import UserUtils from '../../utilities/UserUtils';

const MAKE_POST_HOVER_TEXT = 'Share your thoughts on what is happening in markets right now in 500 characters or less.';
const CREATE_NOTE_HOVER_TEXT = 'Share in-depth research on anything related to financial markets! Use the tools on INVRS to speed up your research process.';

export default {
    emits: ['filter-followed-user-posts'],
     props: {
        selectedGroup: {
            type: Object,
            required: false,
        },
        showFeedSourceDropdown: {
            type: Boolean,
            required: false,
            default: true
        },
        allowPosts: {
            type: Boolean,
            required: false,
            default: true
        },
        selectedGroupSubFeed: {
            type: Object,
            required: false,
        },
        showNotesOnlyButton: {
            type: Boolean,
            required: false,
            default: false
        },
        disablePost: {
            type: Boolean,
            required: false,
            default: false
        },
        disableAsk: {
            type: Boolean,
            required: false,
            default: false
        },
        showPostMotionButton: {
            type: Boolean,
            required: false,
            default: true
        },
        selectedContest: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            // Constants
            MAKE_POST_HOVER_TEXT,
            CREATE_NOTE_HOVER_TEXT,
            userUtils: null,
            filterFollowedUserPosts: false,
            
            
            active: 0,
            
            
        };
    },
    created() {
        this.userUtils = UserUtils;
    },
    watch: {
        // selectedFeedType(val) {
        //     console.log("status editor noted Changing feed type to : "+ val);
        //     this.setFeedTypeItem();
        // }
    },

    computed: {
        ...mapState(['users', 'selectedFeedType', 'showNotesOnly']),
       
        // myGroupsDiscoverMenuItems: [
        //         { label: 'My Groups', action: this.viewMyGroupsFeed, selected: false },
        //         { label: 'Discover', action: this.viewDiscoverFeed, selected: false },
        //     ],

        menuItems() {
            let items= [
                { index: 0, label: 'Latest', feedType: 'latest', tooltip:{value: 'Latest Posts across all public feeds.', class: 'feed-tooltip'}},
                { index: 1, label: 'Q&A', feedType: 'questions', tooltip:{value: 'All Posted Questions.', class: 'feed-tooltip'}},
                { index: 2, label: 'Motions', feedType: 'motions',tooltip:{value: 'All Posted Trade Motions', class: 'feed-tooltip'},feedDisabled:!this.userUtils.isFeatureEnabled('collaborativePortfoliosEnabled') },
                 
                
                
            ];
            if( !this.selectedGroup ) {
                items.push({ index: 3, label: 'Welcome', feedType: 'featuredFeed',tooltip:{value: 'Welcome Feed', class: 'feed-tooltip'} });
                items.push( { index: 4, label: 'Following', feedType: 'yourFeed',tooltip:{value: 'All the latest Posts from Members you Follow.', class: 'feed-tooltip'} });
            }
            return items;
        }
    },
    components: {
        //Dropdown,
        //UserAvatar,
        // BlockUI,
        StatusEditorDialog,
        // PillMenuBar,
        //MoreOptionsMenu
        TabMenu,
    },

    mounted() {
        this.setFeedTypeItem();
    },
    
    methods: {
        setFeedTypeItem() {
            //console.log("set feed type item called and "+ this.$store.state.selectedFeedType);
            if(this.$store.state.selectedFeedType && this.$store.state.selectedFeedType != 'latest'){
                switch(this.$store.state.selectedFeedType){
                    
                    case 'questions':
                        this.onFeedChange(this.menuItems[1]);
                        break; 
                    case 'motions':
                        this.onFeedChange(this.menuItems[2])
                        break; 
                    case 'featuredFeed':
                        this.onFeedChange(this.menuItems[3])
                        break;
                    case 'yourFeed':
                        this.onFeedChange(this.menuItems[4])
                        break;
                }
            }
        }, 
        onFeedChange( item) {
            //console.log(item.index+" = " +JSON.stringify(item));
            this.active = item.index;
            this.setSelectedFeed(this.menuItems[this.active]);
        },

        

        toggleShowNotesOnly() {
            this.$store.commit("SET_SHOW_NOTES_ONLY", !this.showNotesOnly);
            EventBus.emit('update-show-notes-only-pref');
        },

        setSelectedFeed(selectedOption){
           
           this.$store.commit("SET_SELECTED_FEED_TYPE", selectedOption.feedType);
           EventBus.emit('update-feed-type-pref');
        },

        refresh() {
            EventBus.emit('refresh-feed');
        },

        clickedFollowedUserPostsOnlyButton() {
            this.filterFollowedUserPosts = !this.filterFollowedUserPosts;
            this.$emit('filter-followed-user-posts');
        },

        openStatusEditorDialog() {
            //console.log("openStatusEditorDialog", this.selectedGroupSubFeed);
            if( this.allowPosts ) {
             this.$refs.statusEditorModal.open(this.selectedGroup, this.selectedGroupSubFeed);
            }
        },

        openQuestionEditorDialog() {
            if( this.allowPosts ) {
             this.$refs.statusEditorModal.open(this.selectedGroup, this.selectedGroupSubFeed, true);
            }
        },

        openCreateMotionModal() {
            EventBus.emit('open-create-motion-modal', {group:this.selectedGroup, accountId: null});
        }
    },
};
</script>

<style>
   /*  .feed-dropdown-menu {
        width: 225px;
    }
    */
    .create-note-tooltip,
    .make-post-tooltip {
        max-width: 250px;
    } 

    .feed-tooltip {
        max-width: 250px;
    }
</style>
<style scoped>

::v-deep(.p-tabmenu-nav) {
    
    text-align: center;
    /* border-top: 1px solid #dee2e6; */
   
    /* border: 1px solid #dee2e6 !important; */
    border-width: 1px 0 0 0 !important;
    /* border-style: solid;
    border-color:#dee2e6;
    border-top-width: 1px;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0; */
    /* border-width: 1px 0 0 0; */

}

::v-deep(.p-menuitem-text){
    margin:auto;
}
::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):not(:hover) .p-menuitem-link) {
    
    border-bottom:none; 
}
::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link) {
    background: #ffffff;
    border-color: #6c757d;
    color: #6c757d;
    border-width:0 0 2px 0;
}

::v-deep(.p-tabmenuitem) {
    padding-top: 0;
    
}

::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem ){
    margin:auto;
    width: unset;
    padding-top: 0;
    padding-bottom: 0.4rem

}
::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link) {
    padding-bottom: .4rem;
    
}


.disabled-motion-button {
     user-select: none;
    opacity: .3;
}
.disabled-feed {
    user-select: none;
    opacity: .3;
}



.make-post-button {
    margin:auto;
    padding: 4px 12px 4px 12px;
}
.make-post-button img {
   position: relative;
   width: 25px;
   top: 2px;
}
.ask-question-button {
    margin:auto;
    padding: 4px 12px 4px 12px;
    overflow: visible;
    position: relative;
}
.ask-question-button img {
    width: 25px;
    margin-right: 5px;
}
.ask-question-button ::v-deep(.p-badge) {
    position: absolute;
    right: -12px;
    top: -6px;
    padding: 6px 6px;
    font-size: 8px;
    line-height: 0.6;
    height: 16px;
    background: #33CC99;
    color: #FFFFFF;
}
.create-motion-button {
    margin:auto;
    padding: 4px 12px 4px 12px;
    overflow: visible;
    position: relative;
}
.create-motion-button img {
    margin-right: 5px;
    width: 25px;
}

.create-motion-button ::v-deep(.p-badge) {
    position: absolute;
    right: -12px;
    top: -6px;
    padding: 6px 6px;
    font-size: 8px;
    line-height: 0.6;
    height: 16px;
    background: #33CC99;
    color: #FFFFFF;
}

.pi, .pi-refresh{
    margin-right: 10px;
    
    width: 22px;
    height: 22px;

    /* INVRS Blue */

    background: transparent;
    border: none;
} 
::v-deep(.latestFeed .p-inputtext){
    border: transparent;
    box-shadow: none !important;
    background-color: transparent;
    font-family: 'Trebuchet MS';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #32364E;
    overflow: visible;
    align-items: right;
    margin-right: 0;
    padding-left: 0;
}
::v-deep(.latestFeed){

    /* Latest */
    width: auto;
    height: 19px;

    /* Subtitle/ Subtitle 1 - 16|Auto */
    font-family: 'Trebuchet MS';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    /* identical to box height */
    display: flex;
    align-items: center;
    letter-spacing: 0.0015em;

    /* INVRS Blue */
    color: #32364E;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    border: none !important;
}

::v-deep(.p-component), ::v-deep(*:not(.pi, [class*='material-icons'])) {
    font-family: 'Trebuchet MS', 'Verdana';
}

.status-form-container {
    display: flex;
    flex-direction: column;
    border-radius: 30px;
}
.status-form-action-button {
    margin-left: 6px;
    font-size: 10px !important;
    padding: 2px 2px !important;
}

.status-form-right-container {
    width: 98px;
}
.status-form-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    margin: 5px 0;
}
.status-form-top-row {
    display: flex;
    align-items: center;
    padding: 0px 16px 12px 16px;
}
.status-form-bottom-row {
    display: flex;
    align-items: center;
} 

::v-deep(.p-inputtext) {
    color: transparent;
    border-radius: 10px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    margin-right: 16px;
    padding: 8px 0px 8px 16px;
    flex: 1;
}
::v-deep(.p-inputtext:hover) {
    border-color: #BFBFBF;
}
::v-deep(.p-inputtext:focus) {
    box-shadow: none;
    border-color: #BFBFBF;
}
.p-dropdown:not(.p-disabled).p-focus,
::v-deep(.p-dropdown:not(.p-disabled):focus),
::v-deep(.p-inputtext:focus:not(.focus-visible)) {
    outline: none !important;
    box-shadow: none !important;
}
::v-deep(.p-inputtext::placeholder) {
    color: #BFBFBF;
    font-size: 16px;
}
/*
::v-deep(.feed-type-button) {
    border: 1px solid #32364e;
    background-color: #FFFFFF;
    color: #32364e;
    padding: 0px;
    height: 31px;
    border-radius: 30px;
    flex: 0 1 47px;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}
::v-deep(.followed-user-posts-filter-button) {
    margin-right: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}
::v-deep(.followed-user-posts-filter-button.selected) {
    border-color:#32364e;
    background-color: #32364e;
    color: #FFFFFF;
}
::v-deep(.feed-type-button:focus) {
    box-shadow: none;
    border-color: #32364e;
}
*/
 ::v-deep(.status-form-bottom-row .p-button) {
    width: 50%;
    color: #32364e;
    background: #FFFFFF;
    border-radius: 0px;
    font-size: 16px;
    border-top: 1px solid #BFBFBF;
    padding: 8x;
    justify-content: center;
} 
::v-deep(.status-form-bottom-row .p-button [class*='material-icons']) {
    margin-right: 8px;
}
::v-deep(.status-form-bottom-row .p-button:enabled:hover) {
    color: #FFFFFF;
    background: #32364e;
}
::v-deep(.status-form-bottom-row .p-button:focus) {
    box-shadow: none;
}
::v-deep(.status-form-bottom-row button) {
    border-right: 1px solid #BFBFBF;
}
::v-deep(.status-form-bottom-row button:last-child) {
    border-right: none;
}

.posts-disabled {
    pointer-events: none;
    opacity: 0.3;
}

::v-deep(.status-form-action-button) {
	border-radius: 16px;
	padding: 6px 16px;
	border: 1px solid #32364e;
	background: #FFFFFF;
	color: #32364e;
}
::v-deep(.status-form-action-button:enabled:hover),
::v-deep(.status-form-action-button.selected) {
	background: #32364e;
	color: #FFFFFF;
	border: 1px solid #32364e;
 }
 ::v-deep(.status-form-action-button:focus) {
	border-color: #32364e;
	box-shadow: none;
 }
</style>